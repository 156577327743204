.locationTable {
    border-collapse: collapse;
    width: 100%;
}

.locationTable td:first-child {
    text-align: right;
    font-weight: bold;
    max-width: 55px;
}
