html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.App {
  text-align: center;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.sidebar {
  background-color: transparent;
  height: calc(100vh - 50px);
}

.map {
  height: calc(100vh - 165px);
  padding: 0px !important;
}

.map-drawer {
  width: calc(100vw - 350px)
}

.map-no-drawer {
  width: 100%;
}

.route {
  font-weight: bold;
}

.sidebar-card {
  border-bottom: 1px solid silver;
  border-radius: 3px;
  margin: 5px;
  padding: 10px;
}

.sidebar-section-title {
  padding-top: 5px;
  font-size: larger;
  text-align: center;
  border-radius: 7px 7px 0px 0px;
}

.sidebar-section-subtitle {
  padding-top: 5px;
  padding-left: 10px;
  text-align: left;
}

.layerOn {
  background-color: yellow;
}

.spacer {
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 300px;
}

.marqueeWrapper {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.marquee {
  color: white;
  font-weight: bold;
  font-size: x-large;
}

.marquee-warning {
  background-color: #ff0000;
}

.marquee-info {
  background-color: #2484c4;
}

.tt-icon-text {
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: 16px;
}

.tt-icon-text-s {
  position: absolute;
  left: 14px;
  top: 11px;
}

.tt-icon-text-s-sm {
  position: absolute;
  left: 17px;
  top: 12px;
}

.tt-icon-text-n {
  position: absolute;
  left: 13px;
  top: 18px;
}

.tt-icon-text-n-sm {
  position: absolute;
  left: 17px;
  top: 18px;
}

.tt-icon-text-e {
  position: absolute;
  left: 9px;
  top: 15px;
}

.tt-icon-text-e-sm {
  position: absolute;
  left: 13px;
  top: 15px;
}

.tt-icon-text-w {
  position: absolute;
  left: 16px;
  top: 15px;
}

.tt-icon-text-w-sm {
  position: absolute;
  left: 21px;
  top: 15px;
}

.tt-icon-text-se {
  position: absolute;
  left: 20px;
  top: 15px;
}

.tt-icon-text-se-sm {
  position: absolute;
  left: 16px;
  top: 12px;
}

.tt-trend-up {
  background-color: red;
}

.tt-trend-down {
  background-color: green;
}

.profileMap {
  min-width: 300px;
  width: 100%;
  height: 100%;
  margin: auto;
}

.withOverflow {
  overflow: visible;
}

@media (prefers-color-scheme: dark) {


   a,
   a:link,
   a:hover {
    color: silver;
  }

  a:visited {
    color: #a9a9a9;
  }

  .MuiAvatar-root a,
  .MuiAvatar-root a:link,
  .MuiAvatar-root a:visited,
  .MuiAvatar-root a:hover {
    color: black;
  }

  .infoWindow {
    color: black;
  }

  .sidebar-section-title {
    background-color: rgba(255, 255, 255, 0.3);
  }

  .sidebar-section-subtitle {
    background-color: rgba(255, 255, 255, 0.1);
  }

}

@media (prefers-color-scheme: light) {

  .MuiAvatar-root a,
  .MuiAvatar-root a:link,
  .MuiAvatar-root a:visited,
  .MuiAvatar-root a:hover {
    color: white;
  }

  .sidebar-section-title {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .sidebar-section-subtitle {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .tt-icon-text {
    color: black;
  }

}

.center {
  text-align: center;
}

.signal-cluster {
  background:
    radial-gradient(circle, rgba(255, 255, 255, 1) 0% 40%, rgba(255, 255, 255, 0) 42%),
    radial-gradient(circle, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, .5)),
    conic-gradient(from 355deg, red 0deg 1deg, green 6deg 120deg, yellow 125deg 240deg, red 245deg 360deg);
  /*repeating-conic-gradient(from 3deg at 50% 50%, red 3deg 33deg, green 35deg 63deg, yellow 65deg 93deg, red 94deg)*/
  border-radius: 50%;
}

.parking-cluster {
  background:
    radial-gradient(circle, rgba(255, 255, 255, 0.8) 0% 40%, rgba(255, 255, 255, 0) 42%),
    radial-gradient(circle, rgba(14, 93, 162, 0.7) 51%, rgba(14, 93, 162, 1) 80%);
  border-radius: 50%;
}

.lastUpdate {
  padding: 1px;
  font-size: 12px;
  white-space: nowrap;
  font-style: italic;
  text-align: center;
}

.color-bar {
  padding: 5px;
  color: white;
  margin: 1px;
}

.no-congestion-seg {
  background-color: rgb(0,173,0);
}

.light-congestion-seg {
  background-color: rgb(99, 214, 104);
} 

.med-congestion-seg {
  background-color: rgb(255,151,77);
}

.hvy-congestion-seg {
  background-color: rgb(242,60,50);
}

.vhvy-congestion-seg {
  background-color: rgb(129,31,31);
}

.no-data {
  background-color: rgb(127,127,127);
}