.dmsPhase {
    border: 3px ridge #A9A9A9;
    height: calc(3em + 10px);
    line-height: 1em;
    border-radius: 5px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    padding: 3px 0px 3px 2px;
    background-color: rgb(0,0,0);
    color: rgb(221,117,0);
    font-weight: bold;
}

.jp2 {
    justify-content: flex-start;
}

.jp3 {
    justify-content: center;
}

.jp4 {
    justify-content: flex-end;
}

.dmsLine {
    
}

.jl2 {
    text-align: left;
}

.jl3 {
    text-align: center;
}

.jl4 {
    text-align: right;
}

.jl5 {
    text-justify: inter-word;

}